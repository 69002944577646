import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FrontComponent } from './front.component';
import { PlayerComponent } from './player/player.component';
import { MainContentModule } from './main-content/main-content.module';
import { FrontRoutingModule } from './front-routing.module';
import {MatSliderModule} from '@angular/material/slider';
import { FormsModule } from '@angular/forms';
import { MinuteSecondsPipe } from './dates.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


@NgModule({
  declarations: [FrontComponent, PlayerComponent, MinuteSecondsPipe],
  providers: [MinuteSecondsPipe],
  imports: [
    CommonModule,
    FrontRoutingModule,
    MainContentModule,
    FormsModule,
    MatSliderModule,
    FontAwesomeModule
  ],
  bootstrap: [],
})
export class FrontModule { }
