import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'secondsToDate'
})

export class MinuteSecondsPipe implements PipeTransform {

    transform(secs: number): string {
        var date = new Date(1970,0,1);
        date.setSeconds(secs);
        return date.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, "$1");
      }
 

  }