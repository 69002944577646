import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MixesComponent } from './mixes/mixes.component';
import { AboutComponent } from './about/about.component';
import { MainContentComponent } from './main-content.component';

const routes: Routes = [ 
  { path: '', component: MixesComponent },
  { path: 'mixes', component: MixesComponent},
  { path: 'about', component: AboutComponent},
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule],  
  declarations: []

})
export class MainContentRoutingModule { }  
