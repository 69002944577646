import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FrontModule } from './front/front.module'; 
import { FrontComponent } from './front/front.component';

const routes: Routes = [
  {path:'', 
  children: [
    {path:'', 
    loadChildren: () => import('./front/front.module')
    .then(m => m.FrontModule)}
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes), 
    FrontModule
   ],
  exports: [RouterModule]
})
export class AppRoutingModule { } 
