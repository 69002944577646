import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainContentComponent } from './main-content.component';
import { MixesComponent } from './mixes/mixes.component';
import { AboutComponent } from './about/about.component';
import { MainContentRoutingModule } from './main-content-routing.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {TimeAgoPipe} from 'time-ago-pipe';


@NgModule({
  declarations: [MainContentComponent, MixesComponent, AboutComponent, TimeAgoPipe],
  imports: [
    CommonModule,
    MainContentRoutingModule, 
    FontAwesomeModule,
    FormsModule,
    InfiniteScrollModule
     
  ],
  exports: [MainContentComponent]
})
export class MainContentModule { }
