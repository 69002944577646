import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import {debounceTime, distinctUntilChanged} from "rxjs/internal/operators";

import { Mix } from './front/mix';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MixService {

  webserviceUrl = "https://dubstepr.maisbaixo.com/webservice.php";
  
  public playingMixObservable = new Subject<Mix>();
  constructor(private http : HttpClient) { }

  /** GET from the server */
  private getMixes (pagina : number = 1, pesquisa : string = ""){
    return this.http.get<Mix[]>(this.webserviceUrl + "?pagina=" + pagina + "&pesquisa=" + pesquisa);
  }

  private _currentMixes = new BehaviorSubject<Mix[]>([]);
  currentMixes = this._currentMixes.asObservable();
  
  setCurrentMixes(pagina : number = 1, pesquisa : string = "") {
    this.getMixes(pagina, pesquisa)
      .pipe(debounceTime(3000), distinctUntilChanged())
      .subscribe(value => { 
      this._currentMixes.next(value)});
  }

  setPlayingMix(mix : Mix) {
    this.playingMixObservable.next(mix);
  }

}
