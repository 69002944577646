import { Component, OnInit } from '@angular/core';
import { MixService } from 'src/app/mix.service';
import { Mix } from '../../mix';
import { faPlay,faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { faSoundcloud } from '@fortawesome/free-brands-svg-icons';
import { fadeInAnimation } from 'src/app/_animations/fade-in.animation';

@Component({
  selector: 'app-mixes',
  templateUrl: './mixes.component.html',
  styleUrls: ['./mixes.component.css'],
  animations: [fadeInAnimation]
})
export class MixesComponent implements OnInit {
  
  faPlay = faPlay;
  faSoundcloud = faSoundcloud;
  faUserAlt = faUserAlt;

  listaMixes : Mix[] = [];
  searchTerm : string = "";
  paginaCurrent : number = 1;

  constructor(private mixService : MixService) { }

  ngOnInit() {
    this.mixService.currentMixes.subscribe(value => 
                  {
                    this.listaMixes = this.listaMixes.concat(value);
                  })
    this.mixService.setCurrentMixes(this.paginaCurrent,this.searchTerm);
  }

  searchOnChange() {
    this.listaMixes = [];
    this.mixService.setCurrentMixes(1,this.searchTerm);
  }

  searchThisKeyword (mix : Mix) {
    this.searchTerm = mix.usernamer;
    this.searchOnChange();
  }

  onSelect(mix : Mix) {
    this.mixService.setPlayingMix(mix);
  }

  onScrollDown (ev) {
   this.paginaCurrent++;
   this.mixService.setCurrentMixes(this.paginaCurrent,this.searchTerm);

   console.log(this.paginaCurrent);
  }
  

}
