import { Component, OnInit, Input, SimpleChange, ViewChild, ChangeDetectorRef, ElementRef, Pipe, Output, EventEmitter } from '@angular/core';
import { MixService } from 'src/app/mix.service';
import { Mix } from '../mix';
import { MinuteSecondsPipe } from '../dates.pipe';
import { MatSliderChange } from '@angular/material/slider';
import { faPlay,faPause } from '@fortawesome/free-solid-svg-icons';
import { faSoundcloud } from '@fortawesome/free-brands-svg-icons';
import { BehaviorSubject } from 'rxjs';



@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.css']
})
export class PlayerComponent implements OnInit {

  mix : Mix;
  apiKey = '24074406028ac74d7bce7b9c0e744475';
  // Material Style Basic Audio Player Title and Audio URL
  fileToPlay = "";
  faPlay = faPlay;
  faPause = faPause;
  faSoundcloud = faSoundcloud;

  @ViewChild('stream', {static: false}) stream: ElementRef;
  @ViewChild('progressBarStatus', {static: false}) progressStatus: ElementRef;

  audioContainer: HTMLAudioElement;
  isPlaying = false;

  firstRun = true;
  private showPlayer: boolean = false; 

  //Slider
  sliderMin = 0;
  sliderMax : number;
  sliderCurrent = 0;
  totalDuration = 0;
  isMovingSlider = false;

  private _currentTimeStatus = new BehaviorSubject<number>(0); 
  currentTimeStatus = this._currentTimeStatus.asObservable();
  setCurrentTimeStatus(value: number) {
    this._currentTimeStatus.next(value);
  }
  currentTimeStatusNumber : number = 0;

  constructor(private mixService : MixService, private minuteSecondsPipe: MinuteSecondsPipe) { 
    //console.log("constructor");
    if (this.fileToPlay != '') {
      this.showPlayer = true;
    }
  }

  ngOnInit() {
    this.currentTimeStatus.subscribe((value : number) => {
      this.currentTimeStatusNumber = value;
      this.sliderCurrent = value;
    })

    //console.log("ngOnInit");
    this.mixService.playingMixObservable.subscribe(value => {
      this.mix = value;
      this.fileToPlay = value.api  + '/stream?client_id='+this.apiKey;
      this.showPlayer = true;
      this.totalDuration =  this.millisToSeconds(+value.duration);
      this.sliderMax = (this.totalDuration);
      this.sliderCurrent = 0;
      this.isPlaying = true;
    });
  }

  ngAfterViewChecked() {
    //console.log("ngAfterViewChecked");
    if(this.stream) {
       this.audioContainer = this.stream.nativeElement;
       if(this.firstRun)  {
        this.audioContainer.addEventListener("timeupdate", (currentTime)=>{
          if(!this.isMovingSlider)
            this.sliderCurrent = this.audioContainer.currentTime;
            this.setCurrentTimeStatus(this.audioContainer.currentTime);
        });
        this.firstRun = false;
       }
    }
  }

  onMovingTicks(val) {
    this.isMovingSlider = true;
  }

  onChangeSlider (newValue : number) {
    //this.sliderCurrent = newValue;
    this.audioContainer.currentTime = newValue;
    
  }

  playOrPause() {
    if(this.isPlaying){
      this.isPlaying = false;
      this.audioContainer.pause();
      return;
    }
      this.isPlaying = true;
      setTimeout(()=>{this.audioContainer.play();});
    
    
}

  millisToSeconds(millis : number) {
    return Math.floor((millis/1000));
  }

  changeColor(){
    return "yellow";
  }


}
