import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FrontComponent } from './front.component';
import { MainContentModule } from './main-content/main-content.module';

const routes: Routes = [
  {path:'', component: FrontComponent,
  children: [
    {path:'', 
    loadChildren: () => import('./main-content/main-content.module')
    .then(m => m.MainContentModule)}
    ]
  }
];
@NgModule({
  imports: [
    RouterModule.forChild(routes), 
   ],
  exports: [RouterModule]
})
export class FrontRoutingModule { } 
